import React from "react";
import NewUserForm from "./NewUserForm";
import UserStateLaws from "./UserStateLaws";

const OverLay = ({ overlay, setOverlay, content }) => {
  const switchContent = () => {
    switch (content) {
      case "new_user":
        return <NewUserForm />;
      default:
        return <UserStateLaws userDetails={content} />;
    }
  };
  return (
    <div
      className={`overlay ${overlay ? "overlay__show" : ""}`}
      onClick={() => setOverlay(!overlay)}
    >
      <span
        className="overlay__close"
        onClick={() => setOverlay(!overlay)}
      ></span>
      <div className="overlay__content" onClick={(e) => e.stopPropagation()}>
        {switchContent(content)}
      </div>
    </div>
  );
};

export default OverLay;
