import React, { useEffect, useState } from "react";
import OverviewCard from "../../components/OverviewCard";
import axios from "axios";
import { adminAPI } from "../../utils/apiStrings";
import ClientTable from "../../components/Admin/ClientTable";
import Paginator from "../../components/Paginator";
import OverLay from "../../components/Admin/OverLay";
import { useNavigate } from "react-router-dom";
import { checkAdmin } from "../../utils/session";
import debounce from "../../utils/debounce";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";

function AdminClients() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filter, setFilter] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterResult, setFilterResult] = useState([]);
  const [response, setResponse] = useState({ status: "", message: "" });
  const [overlayContent, setOverlayContent] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [id, setId] = useState("");

  const [pageLimit, setPageLimit] = useState(5);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  let currentUsers = [];
  const [pageGroup, setPageGroup] = useState(() =>
    new Array(pageLimit).fill().map((_, index) => index + 1)
  );

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleSelection = async (value) => {
    setDropdownVisible(false);
    await axios
      .get(
        `${adminAPI()}/export-clients?search=${searchTerm}&per_page=${
          value !== "all" ? value : ""
        }`,
        { responseType: "blob" }
      )
      .then((response) => {
        const url = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = url;

        link.download = "admin_clients";
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        setSearchTerm("");
        getUsers();
      })
      .catch((error) => console.error("Error exporting file: ", error));
  };

  const changePage = (page) => {
    setCurrentPage(page);
    getUsers(page);
    setPageGroup(getPageGroup(page));
  };

  const getPageGroup = (page) => {
    let start = Math.floor((page - 1) / pageLimit) * pageLimit;
    const newGroup = new Array(pageLimit)
      .fill()
      .map((_, index) => start + index + 1);
    return newGroup;
  };

  useEffect(() => {
    const { session, token } = checkAdmin();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (!session) {
      navigate("login");
    }
  });

  const getUsers = async (page = 1, search = "") => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${adminAPI()}/fetch-clients?page=${page}&search=${search}`
      );
      const {
        data: {
          data: { data },
        },
      } = response;
      // console.log(response.data.data);
      setTotalUsers(response.data.data.total);
      setCurrentPage(response.data.data.current_page);
      setUsers(data);
    } catch (error) {}
    setLoading(false);
  };

  const searchUser = (string) => {
    setSearchTerm(string.toLowerCase());
    debounce(() => getUsers(1, string.toLowerCase()), 1000);
  };

  // const sortBy = (filter) => {
  //   setFilter(filter);
  //   let result;


  //   let currentData = searchResult.length ? searchResult : users;

  //   switch (filter) {
  //     case "lagos":
  //       result = currentData.filter((user) =>
  //         user.full_name.toLowerCase().includes(filter.toLowerCase())
  //       );
  //       break;
  //     case "abia":
  //       result = result = currentData.filter((user) =>
  //         user.full_name.toLowerCase().includes(filter.toLowerCase())
  //       );
  //       break;
  //     default:
  //   }
  //   setFilterResult(result);
  // };
  
  const activateBackDrop = (modal) => {
    setOverlay(true);
    setOverlayContent(modal);
  };
  const closeOptions = () => {
    setId(null);
  };

  useEffect(() => {
    getUsers(1);
  }, []);

  currentUsers = searchResult.length ? searchResult : users;

  return (
    <div className="client">
      <div
        className={`client__response ${
          response.status
            ? response.status?.toString().includes("20")
              ? "client__response--success"
              : "client__response--error"
            : ""
        }`}
        onClick={() => setResponse({ status: "" })}
      >
        {response.message || "Action Successful"} &#x24E7;
      </div>
      <div className="client__cards">
        <OverviewCard
          image="/images/total-users.svg"
          color="purple"
          label="Total Number of Users"
          text={totalUsers}
        />
      </div>

      <div className="client__users">
        <div className="client__actions">
          <div className="client__view">
            <div className="client__search">
              <span className="client__icon client__icon--search"></span>
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                className="client__input"
                value={searchTerm}
                onChange={(e) => searchUser(e.target.value)}
              />
            </div>
            {/* <div className="client__filter">
              <select
                className="client__select"
                name="sort"
                id="sort"
                value={filter}
                onChange={(e) => sortBy(e.target.value)}
              >
                <option value="" disabled="disabled">
                  Filter by State
                </option>
                <option
                  className="client__select--option option1"
                  value="lagos"
                >
                  Lagos State
                </option>
                <option className="client__select--option option2" value="abia">
                  Abia State
                </option>
              </select>
            </div> */}
          </div>
          <div className="client__export" onClick={toggleDropdown}>
            <span className="client__icon--export"></span>
            <span>Export</span>
            {isDropdownVisible && (
              <ul className="dropdown">
                <li onClick={() => handleSelection(20)}>20</li>
                <li onClick={() => handleSelection(100)}>100</li>
                <li onClick={() => handleSelection(500)}>500</li>
                <li onClick={() => handleSelection(1000)}>1000</li>
                <li onClick={() => handleSelection("all")}>All</li>
              </ul>
            )}
          </div>
        </div>
        <div className="client__table">
          {loading ? (
            <Loader />
          ) : users.length === 0 ? (
            <NoData />
          ) : (
            <ClientTable
              data={
                filter !== ""
                  ? filterResult
                  : searchResult.length
                  ? searchResult
                  : users
              }
              setResponse={setResponse}
              id={id}
              setId={setId}
              activateBackDrop={activateBackDrop}
              closeOptions={closeOptions}
            />
          )}
          {currentUsers && (
            <div className="client__paginator">
              <Paginator
                perPage={perPage}
                totalLaws={searchResult?.length || totalUsers}
                changePage={changePage}
                currentPage={currentPage}
                pageGroup={pageGroup}
                setPageGroup={setPageGroup}
              />
            </div>
          )}
        </div>
      </div>
      <OverLay
        overlay={overlay}
        setOverlay={setOverlay}
        content={overlayContent}
      />
    </div>
  );
}

export default AdminClients;
