import React from "react";

const NoData = ({ message = "No Data Available" }) => {
  return (
    <div className="no-data">
      <div className="no-data-icon">📄</div>
      <p className="no-data-message">{message}</p>
    </div>
  );
};

export default NoData;
