import React, { useEffect, useState } from "react";
import OverviewOptions from "./OverviewOptions";

const TransactionTable = ({
  data,
  setResponse,
  closeOptions,
  id,
  setId,
  type,
}) => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email Address</th>
          <th>State Law</th>
          <th>Amount Paid</th>
          <th>Source</th>
          <th>Date of Payment</th>
        </tr>
      </thead>
      <tbody>
        {data.map((transaction, index) => (
          <tr key={index}>
            {/* <tr key={transaction?.client_id}> */}
            <td>{`${transaction.full_name}`}</td>
            <td>{transaction.email}</td>
            <td>{transaction.state_name}</td>
            <td>
              {transaction.amount.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
              })}
            </td>
            <td>
              {transaction.payment_type[0].toUpperCase() +
                transaction.payment_type.substring(1).toLowerCase()}
            </td>
            <td>
              {transaction.paid_at.substring(0, 11)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TransactionTable;
