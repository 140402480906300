import React, { useEffect, useState } from "react";
import OverviewOptions from "./OverviewOptions";

const UserTable = ({ data, setResponse, closeOptions, id, setId }) => {
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<table>
			<thead>
				<tr>
					<th>Name</th>
					<th>Email</th>
					<th>Phone Number</th>
					<th>State ID</th>
					<th>State</th>
					<th>Actions</th>
				</tr>
			</thead>
			<tbody>
				{data.map((user) => (
					<tr key={user.client_id}>
						<td>{`${user.full_name}`}</td>
						<td>{user.email}</td>
						<td>{user.phone_number}</td>
						<td>{user.state_id}</td>
						<td>{user.state}</td>
						<td>
							<span onClick={() => setId(user.client_id)}>...</span>
							<OverviewOptions
								userId={user.client_id}
								id={id}
								setId={setId}
								closeOptions={closeOptions}
								setResponse={setResponse}
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default UserTable;
