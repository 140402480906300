import React, { useEffect, useState } from "react";
import Paginator from "../components/Paginator";
import { LawCard } from "../components/LawCard";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Hero from "../components/Hero";
import { currentEnvironment } from "../utils/environment";
import { checksavedUser } from "../utils/session";
import { useNavigate } from "react-router-dom";
import UserFilter from "../components/UserFilter";
import SalesCard from "../components/SalesCard";
import DatePicker from "react-datepicker";
import State from "../api/Laws";
import { Period } from "../utils/period";

const StateLaws = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [validSession, setValidSession] = useState(false);
  const [validToken, setValidToken] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [laws, setLaws] = useState([]);
  const [stateName, setStateName] = useState("");
  const [allSales, setAllSales] = useState();
  const [annualSub, setAnnualSub] = useState(0);
  const [individualSales, setIndividualSales] = useState(0);
  const [outrightSales, setOutrightSales] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(30);
  const [pageLimit, setPageLimit] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(false);
  const [period, setPeriod] = useState(() => Period.getCurrentQuarter());
  const [searchResult, setSearchResult] = useState();
  const [pageGroup, setPageGroup] = useState(
    new Array(pageLimit).fill().map((_, index) => index + 1)
  );

  const [filterLabel, setFilterLabel] = useState(Period.getPeriodRange());

  let currentLaws = [];
  const pageStop = currentPage * perPage;
  const pageStart = pageStop - perPage;

  const commissionRate = 0.45;

  const getLaws = async (stateId) => {
    setLoading(true);
    try {
      const response = await State.fetchStateLaws(stateId);

      const { laws, name } = response;
      setStateName(name);
      laws.sort((a, b) => a.name.localeCompare(b.name));

      setLaws(laws);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getAnnualSub = async (period) => {
    //Disable request to CRM as only Lagos state is currently recognized
    if (stateName.toLowerCase() !== "lagos") {
      setAnnualSub(0);
      return;
    }

    try {
      const response = await State.getAnnualSub(period);
      const amount =
        response.primsol_plus.commission + response.prime_plus.commission;
      setAnnualSub(amount ?? 0);
    } catch (error) {}
  };

  const filterAnnualSub = async (startDate, endDate) => {
    //Disable request to CRM as only Lagos state is currently recognized
    if (stateName.toLowerCase() !== "lagos") {
      setAnnualSub(0);
      return;
    }

    try {
      const response = await State.filterAnnualSub(startDate, endDate);
      const amount =
        response.primsol_plus.commission + response.prime_plus.commission;
      setAnnualSub(amount ?? 0);
    } catch (error) {}
  };

  const getIndividualSales = async (token, period) => {
    try {
      const response = await State.getIndividualSales(token, period);
      const { outright, nonOutright } = response;

      setOutrightSales(parseInt(outright));
      setIndividualSales(parseInt(nonOutright));
    } catch (error) {
      console.log(error);
    }
  };

  const filterIndividualSales = async (startDate, endDate) => {
    try {
      const response = await State.filterIndividualSales(
        validToken,
        startDate,
        endDate
      );
      const { outright, nonOutright } = response;

      setOutrightSales(parseInt(outright));
      setIndividualSales(parseInt(nonOutright));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (searchString) => {
    setSearchTerm(searchString);
    let match = laws.filter((law) =>
      law.name.toLowerCase().includes(searchString.toLowerCase())
    );
    setSearchResult(match);
    currentLaws = searchResult?.slice(0, perPage);
  };

  const handleDateSearch = () => {
    filterAnnualSub(startDate, endDate);
    filterIndividualSales(startDate, endDate);
    setFilterLabel(Period.formatDateRange(startDate, endDate));
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setPageGroup(getPageGroup(page));
  };

  const getPageGroup = (page) => {
    let start = Math.floor((page - 1) / pageLimit) * pageLimit;

    const newGroup = new Array(pageLimit)
      .fill()
      .map((_, index) => start + index + 1);

    return new Array(pageLimit).fill().map((_, index) => start + index + 1);
  };

  const filterPeriod = (period, quarter) => {
    getAnnualSub(period);
    getIndividualSales(validToken, period);
    setPeriod(period);
    setFilterLabel(Period.getPeriodRange(quarter));
  };

  useEffect(() => {
    //TODO:Address annual sub request for non-lagos states(s)
    const { validSession, token } = checksavedUser();

    setValidSession(validSession);
    setValidToken(token);
    if (validSession) {
      getLaws(params.id);
      getAnnualSub(Period.getCurrentQuarter());
      getIndividualSales(token, Period.getCurrentQuarter());
    } else {
      navigate("/");
    }

    return () => {};
  }, [validSession]);

  currentLaws =
    searchResult?.length > 0
      ? searchResult.slice(pageStart, pageStop)
      : laws?.slice(pageStart, pageStop);

  return loading ? (
    <div className="loading"></div>
  ) : (
    <>
      <Hero stateName={stateName} />
      <div className="content-wrapper">
        <div className="summary">
          <div className="summary__heading">
            <span className="summary__interval">{filterLabel}</span>
            <div className="summary__filter--options">
              <div
                className="summary__filter"
                onClick={() => setFilter(!filter)}
              >
                <div className="summary__filter--icon">
                  <span className="summary__filter--img">
                    <img src="/images/filter.png" alt="" />
                  </span>
                  <span className="summary__filter--text">Period</span>
                </div>
                <div
                  className={`summary__filter--periods ${
                    filter ? "summary__filter--visible" : ""
                  } `}
                >
                  <span
                    className="summary__filter--period"
                    onClick={() => filterPeriod("first_quarter", 1)}
                  >
                    First Quarter
                  </span>
                  <span
                    className="summary__filter--period"
                    onClick={() => filterPeriod("second_quarter", 2)}
                  >
                    Second Quarter
                  </span>
                  <span
                    className="summary__filter--period"
                    onClick={() => filterPeriod("third_quarter", 3)}
                  >
                    Third Quarter
                  </span>
                  <span
                    className="summary__filter--period"
                    onClick={() => filterPeriod("fourth_quarter", 4)}
                  >
                    Fourth Quarter
                  </span>
                </div>
              </div>
              <div className="summary__filter--date">
                <div className="summary__filter--start">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showIcon
                    withPortal
                    showYearDropdown
                    dropdownMode="scroll"
                    maxDate={new Date()}
                  />
                </div>
                <div className="summary__filter--end">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showIcon
                    withPortal
                    showYearDropdown
                    dropdownMode="scroll"
                    maxDate={new Date()}
                  />
                </div>
                <button
                  className="summary__filter--search"
                  onClick={() => handleDateSearch()}
                >
                  <span className="summary__filter--text">Search</span>
                </button>
              </div>
            </div>
          </div>
          <div className="summary__cards">
            <SalesCard
              label={"Total Sales"}
              value={annualSub + outrightSales + individualSales}
              icon={"wallet"}
              color={"green"}
            />
            <SalesCard
              label={"Sales from Annual Subscription"}
              value={annualSub}
              icon={"tag"}
              color={"blue"}
            />
            <SalesCard
              label={"Sales from Outright Purchase"}
              value={outrightSales}
              icon={"tag"}
              color={"orange"}
            />
            <SalesCard
              label={"Sales from Individual Laws"}
              value={individualSales}
              icon={"tag"}
              color={"orange"}
            />
            <SalesCard
              label={"Royalty"}
              value={
                (annualSub + outrightSales + individualSales) * commissionRate
              }
              icon={"cost-total"}
              color={"pink"}
              child={<span className="card-label__super">45%</span>}
            />
          </div>
        </div>
        <div className="laws">
          <div className="laws__heading">
            <p className="heading-title">{`All Laws ( ${laws?.length}) `}</p>
            <div className="heading-options">
              <div className="search">
                <div className="search__desc">
                  <img
                    className="search__desc--icon"
                    src="/images/search.png"
                    alt=""
                  />
                </div>
                <input
                  id="search"
                  className="search__input"
                  type="text"
                  name="search"
                  placeholder="Search Law..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="laws__container">
            {currentLaws.length ? (
              currentLaws.map((law) => (
                <LawCard
                  law={law}
                  url={`${location.pathname}/laws/${law.id}`}
                  key={law.id}
                />
              ))
            ) : (
              <div className="loading"></div>
            )}
          </div>
          {currentLaws.length && (
            <Paginator
              perPage={perPage}
              totalLaws={searchResult?.length || laws?.length}
              changePage={changePage}
              currentPage={currentPage}
              pageGroup={pageGroup}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StateLaws;
