import React, { useEffect, useState, useRef, Suspense } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { stripHtml } from "../utils/stringOperator";
import chartOptions from "../utils/chartOptions";
import axios from "axios";
import { currentEnvironment } from "../utils/environment";
import { checksavedUser } from "../utils/session";
import OverviewCard from "./OverviewCard";

Chart.register(...registerables);
const devEnvironment = currentEnvironment();
const LawDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const amountData = new Array(12).fill(0);
  const [lawInfo, setLawInfo] = useState();
  const [purchaseDetails, setPurchaseDetails] = useState();
  let [storeSales, setStoreSales] = useState(0);
  let [primeSales, setPrimeSales] = useState(0);
  const [individualSales, setIndividualSales] = useState({
    sale: "",
    unit: "",
  });
  const [outrightSales, setOutrightSales] = useState(0);
  const [subSales, setSubSales] = useState(0);
  const [chartData, setChartData] = useState({
    labels: months,
    datasets: [
      {
        data: amountData,
      },
    ],
  });

  const getBreakdown = (data) => {
    let sortedData = Object.entries(data).map((entry) => {
      if (checkQuarter(entry[0])) {
        entry[1].reduce((totalSale, sale) => {
          sale.source_app === "prime"
            ? setPrimeSales((primeSales += sale.amount))
            : setStoreSales((storeSales += sale.amount));
        }, 0);
      }
      return {
        month: entry[0],
        amount: entry[1].reduce((totalSale, sale) => {
          return totalSale + sale.amount;
        }, 0),
      };
    });
    if (sortedData) {
      getChartData(sortedData);
    }
  };

  const getChartData = (data) => {
    data.forEach((data) => {
      let monthIndex = months.findIndex((month) => month === data.month);
      amountData[monthIndex] = data.amount / 100;
    });
    setChartData({
      labels: months,
      datasets: [
        {
          data: amountData,
          fill: true,
          backgroundColor: ["rgba(65, 105, 225, 0.3)"],
        },
      ],
    });
  };
  const getCurrentQuarter = (date = new Date()) => {
    return Math.floor(date.getMonth() / 3 + 1);
  };

  const checkQuarter = (month) => {
    const quarter = Math.floor(months.indexOf(month) / 3 + 1);

    return quarter === getCurrentQuarter();
  };

  const fetchPurchaseDetails = async (
    token,
    year = new Date().getFullYear()
  ) => {
    const response = await axios.get(
      `https://store${
        devEnvironment ? "-staging" : ""
      }.lawpavilion.com/api/laws-purchase-details?law_id=${
        params.id
      }&year=${year}`,
      { headers: { Authorization: "Bearer " + token } }
    );
    const { summary, breakdown } = response.data.data;
    getBreakdown(breakdown);
  };

  const fetchlawInfo = async () => {
    const response = await axios.get(
      `https://store${
        devEnvironment ? "-staging" : ""
      }.lawpavilion.com/api/states/24/laws/${
        params.id
      }/information?full_info=true`
    );

    setLawInfo(response.data.data);
  };

  const getStoreSales = async (token) => {
    const response = await axios.get(
      `https://store${
        devEnvironment ? "-staging" : ""
      }.lawpavilion.com/api/state-law/${
        params.id
      }/all-sales?year=${new Date().getFullYear()}`,
      { headers: { Authorization: "Bearer " + token } }
    );

    const { individual_count, outright_count } = response.data.data;
    setIndividualSales({
      sale: parseInt(response.data.data.total),
      unit: individual_count,
    });
    setOutrightSales(outright_count);
  };

  const getSubSales = () => {
    const APP_KEY = devEnvironment
      ? process.env.REACT_APP_CRM_APP_KEY
      : process.env.REACT_APP_CRM_APP_KEY_LIVE;
    const AUTH = devEnvironment
      ? process.env.REACT_APP_CRM_AUTHORIZATION
      : process.env.REACT_APP_CRM_AUTHORIZATION_LIVE;
    axios
      .get(
        `https://crm${
          devEnvironment ? "-staging" : ""
        }.lawpavilion.com/api/v2/transactions/lagos-state-laws?APP_KEY=${APP_KEY}&year=${new Date().getFullYear()}`,
        {
          headers: {
            "Content-type": "application/.json",
            Authorization: AUTH,
          },
        }
      )
      .then((response) => {
        const { primsol_plus, prime_plus } = response.data.data;

        setSubSales(prime_plus.business + primsol_plus.business);
      });
  };

  const getSalesGraph = async (token) => {
    const response = await axios.get(
      `https://store${
        devEnvironment ? "-staging" : ""
      }.lawpavilion.com/api/state-law/${
        params.id
      }/all-sales-graph?year=${new Date().getFullYear()}`,
      { headers: { Authorization: "Bearer " + token } }
    );
  };

  useEffect(() => {
    const { validSession, token } = checksavedUser();
    if (validSession) {
      fetchlawInfo();
      fetchPurchaseDetails();
      getSubSales();
      getStoreSales(token);
      getSalesGraph(token);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="content-wrapper">
      <div class="law-nav" onClick={() => navigate(-1)}>
        Back
      </div>
      <div className="law-card">
        <div className="law-card__info">
          <div className="law-img">
            <img src="/images/law.png" alt="law book cover" />
          </div>
          <div className="law-desc">
            <h3 className="law-desc__title">{lawInfo?.name}</h3>
            <p className="law-desc__intro">{stripHtml(lawInfo?.long_title)}</p>
          </div>
        </div>
        <div className="law-card__copies">
          <OverviewCard
            image="/images/tag.png"
            label="Sales from product Subscription"
            text={`${subSales + outrightSales} Copies`}
            color="blue"
          />
          <OverviewCard
            image="/images/tag.png"
            label={"Sales from Individual Laws"}
            text={`${individualSales.unit || 0} Copies`}
            color="orange"
          />
        </div>
      </div>

      <div className="chart">
        <Line data={chartData} options={chartOptions} id="salesChart" />
      </div>
    </div>
  );
};

export default LawDetails;
