import axios from "axios";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import FormHeadings from "../components/FormComponents/FormHeadings";
import { userAuthAPI } from "../utils/apiStrings";
import * as Yup from "yup";
import Input from "../components/FormComponents/Input";
import Password from "../components/FormComponents/Password";
import Button from "../components/FormComponents/Button";

const ResetPassword = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const [apiError, setApiError] = useState("");

	const handlePasswordReset = (values) => {
		setLoading(true);
		axios
			.post(`${userAuthAPI()}/reset-password`, {
				otp: values.otp,
				new_password: values.new_password,
				confirm_password: values.confirm_password,
			})
			.then((res) => {
				if (res.status === 200) {
					navigate(`/`);
				}
			})
			.catch((err) => {
				setLoading(false);
				setApiError(err.response.data.message);
			});
	};

	useEffect(() => {}, [apiError]);

	return (
		<div className="auth">
			<div className="auth__form-wrapper">
				<div className="auth__cards">
					<div className="auth__card auth__card--form">
						<FormHeadings
							heading="Reset Password"
							subheading="Change your password with the OTP sent to your mail"
						/>
						<Formik
							initialValues={{
								otp: "",
								new_password: "",
								confirm_password: "",
							}}
							validationSchema={Yup.object({
								otp: Yup.number().required("Enter OTP code"),
								new_password: Yup.string().required(
									"New password required"
								),
								confirm_password: Yup.string().oneOf(
									[Yup.ref("new_password"), null],
									"Passwords must match"
								),
							})}
							onSubmit={handlePasswordReset}>
							<Form className="auth__form">
								<Input
									label="OTP Code"
									className="auth__form-control"
									type="text"
									name="otp"
									id="otp"
								/>
								<Password
									label="New Password"
									className="auth__form-control"
									name="new_password"
									id="new_password"
								/>
								<Password
									label="Confirm Password"
									className="auth__form-control"
									name="confirm_password"
									id="confirm_password"
								/>
								{apiError ? (
									<span className="auth__error">
										{apiError}
									</span>
								) : (
									""
								)}
								<Button
									type="submit"
									text="Reset Password"
									loading={loading}
								/>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
			<div className="auth__hero">
				<div className="auth__hero--headings">
					<h3>Summary of State Laws Purchased</h3>
					<h4>
						For simple, organized and cumulative access to the
						insights of the purchase of State Laws.
					</h4>
				</div>
				<div className="auth__hero--image">
					<img
						src="/images/dashboard.png"
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
