let debounceTimeout;

function debounce(functionToCall, delay) {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  debounceTimeout = setTimeout(async () => {
    await functionToCall();
  }, delay);
}
export default debounce;
