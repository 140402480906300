import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { checksavedUser } from "../utils/session";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormHeadings from "../components/FormComponents/FormHeadings";
import Input from "../components/FormComponents/Input";
import Button from "../components/FormComponents/Button";
import Password from "../components/FormComponents/Password";
import CheckBox from "../components/FormComponents/CheckBox";
import { userAuthAPI } from "../utils/apiStrings";

function Login() {
  const navigate = useNavigate();
  const initialFormValues = {
    email: "",
    password: "",
    remember: false,
  };
  const formValidationSchema = {
    email: Yup.string()
      .email("Invalid email address")
      .required("Fill in email address"),
    password: Yup.string().required("Fill in password"),
    remember: Yup.boolean(),
  };
  const [apiError, setApiError] = useState("");

  const [error, setError] = useState({
    email: "",
    password: "",
    response: "",
  });

  const [loading, setLoading] = useState(false);

  const handleLogin = (values) => {
    setLoading(true);
    const handleLogin = (values) => {
      setLoading(true);

      axios
        .post(`${userAuthAPI()}/login`, {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          const { data } = res.data;
          if (values.remember) {
            localStorage.setItem(
              "admin_state_laws",
              JSON.stringify({ ...data, logTime: new Date() })
            );
          }
          sessionStorage.setItem(
            "admin_state_laws",
            JSON.stringify({ ...data, logTime: new Date() })
          );
          axios
            .post(`${userAuthAPI()}/login`, {
              email: values.email,
              password: values.password,
            })
            .then((res) => {
              const { data } = res.data;
              if (values.remember) {
                localStorage.setItem(
                  "admin_state_laws",
                  JSON.stringify({ ...data, logTime: new Date() })
                );
              }
              sessionStorage.setItem(
                "admin_state_laws",
                JSON.stringify({ ...data, logTime: new Date() })
              );

              navigate(`/states/${data.state.state_id}`);
            })
            .catch((err) => {
              setLoading(false);
              setApiError(err?.response?.data?.message || err?.message);
            });

          navigate(`/states/${data.state.state_id}`);
        })
        .catch((err) => {
          setLoading(false);
          setApiError(err.response.data.message);
        });
    };
  };
  useEffect(() => {
    const { validSession, stateId } = checksavedUser();
    if (validSession) {
      navigate(`/states/${stateId}`);
    }
  }, [error]);
  useEffect(() => {
    const { validSession, stateId } = checksavedUser();
    if (validSession) {
      navigate(`/states/${stateId}`);
    }
  }, [error]);

  return (
    <div className="auth">
      <div className="auth__form-wrapper">
        <div className="auth__cards">
          <div className="auth__card auth__card--form">
            <FormHeadings
              heading="Welcome Back"
              subheading="Access to LawPavilion Lawrecom Dashboard"
            />
            <Formik
              initialValues={initialFormValues}
              validationSchema={Yup.object(formValidationSchema)}
              onSubmit={handleLogin}
            >
              <Form className="auth__form">
                <Input
                  label="Email Address"
                  className="auth__form-control"
                  type="email"
                  name="email"
                  id="email"
                />
                <Password
                  label="Password"
                  className="auth__form-control"
                  name="password"
                  id="password"
                />
                <div className="auth__options">
                  <CheckBox
                    name="remember"
                    id="remember"
                    text="Remember Me"
                  ></CheckBox>
                  <Link to="/forgot-password" className="auth__forgot">
                    Forgot Password
                  </Link>
                </div>
                {apiError ? (
                  <span className="auth__error">{apiError}</span>
                ) : null}
                <Button type="submit" text="Sign In" loading={loading} />
              </Form>
            </Formik>
          </div>
          <div className="auth__card auth__card--support">
            <p className="auth__support--heading">For Support:</p>
            <div className="auth__contacts">
              <div className="auth__support">
                <img src="/images/phone.png" alt="phone contact" />
                <div>
                  <p className="auth__contact">+234 805 029 8724</p>
                  <p className="auth__contact">+234 805 029 8727</p>
                </div>
              </div>
              <div className="auth__support">
                <img src="/images/mail.png" alt="mail auth__contact " />
                <a href="mailto:customercare@lawpavilion.com">
                  customercare@lawpavilion.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="auth__hero">
        <div className="auth__hero--headings">
          <h3>Summary of the Lagos State Laws Purchased</h3>
          <h4>
            For simple, organized and cumulative access to the insights of the
            purchase of Lagos State Laws.
          </h4>
        </div>
        <div className="auth__hero--image">
          <img src="/images/dashboard.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Login;
