import React, { useEffect, useState } from "react";

const Paginator = ({
  perPage,
  totalLaws,
  changePage,
  currentPage,
  pageGroup,
  setPageGroup,
}) => {
  const pageLimit = Math.ceil(totalLaws / perPage);
  const [pager, _] = useState(5);
  const [showAllPages, setshowAllPages] = useState(false);

  const handleShowAllPages = () => {
    if (showAllPages) {
      setshowAllPages(false);
      return setPageGroup(() => {
        let start = Math.floor((currentPage - 1) / pager) * pager;
        const newGroup = new Array(pager)
          .fill()
          .map((_, index) => start + index + 1);
        return newGroup;
      });
    } else {
      setshowAllPages(true);

      return setPageGroup(() => {
        return new Array(pageLimit).fill().map((_, index) => index + 1);
      });
    }
  };
  useEffect(() => {
    setshowAllPages(false);
  }, [currentPage]);

  return (
    <div className="paginator">
      <span
        className={`paginator__control ${
          currentPage === 1 && "paginator__hide"
        }`}
        onClick={() => changePage(currentPage - 1)}
      >
        &#xab;
      </span>
      {pageGroup.map((page) => (
        <span
          key={page}
          className={`paginator__control ${
            page > pageLimit && "paginator__hide "
          } ${currentPage === page && "paginator__control--active"}`}
          onClick={() => changePage(page)}
        >
          {page}
        </span>
      ))}
      <span
        className={`paginator__control `}
        onClick={(e) => handleShowAllPages(e)}
      >
        ...
      </span>
      <span
        className={`paginator__control ${
          currentPage === pageLimit && "paginator__hide"
        }`}
        onClick={() => changePage(currentPage + 1)}
      >
        &#xbb;
      </span>
    </div>
  );
};

export default Paginator;
