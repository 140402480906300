import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormHeadings from "../components/FormComponents/FormHeadings";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../components/FormComponents/Input";
import Button from "../components/FormComponents/Button";
import { userAuthAPI } from "../utils/apiStrings";

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const [error, setError] = useState("");

	const sendResetRequest = (values) => {
		setLoading(true);
		axios
			.post(`${userAuthAPI()}/forgot-password`, { email: values.email })
			.then((res) => {
				if (res.status === 200) {
					navigate("/reset-password");
				}
			})
			.catch((err) => {
				setLoading(false);
				setError(err.response.data.message);
			});
	};

	useEffect(() => {
		return () => {};
	}, [error]);

	return (
		<div className="auth">
			<div className="auth__form-wrapper">
				<div className="auth__cards">
					<div className="auth__card auth__card--form">
						<FormHeadings
							heading="Forgot Password"
							subheading="Send a password request by specifying your email
							address below"
						/>
						<Formik
							initialValues={{ email: "" }}
							validationSchema={Yup.object({
								email: Yup.string()
									.email("Invalid Email")
									.required("Fill in email address"),
							})}
							onSubmit={sendResetRequest}>
							<Form className="auth__form">
								<Input
									label="Email Address"
									className="auth__form-control"
									type="email"
									name="email"
									id="email"
								/>{" "}
								{error ? (
									<span className="auth__error">{error}</span>
								) : (
									""
								)}
								<Button
									type="submit"
									text="Send"
									loading={loading}
								/>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
			<div className="auth__hero">
				<div className="auth__hero--headings">
					<h3>Summary of State Laws Purchased</h3>
					<h4>
						For simple, organized and cumulative access to the
						insights of the purchase of State Laws.
					</h4>
				</div>
				<div className="auth__hero--image">
					<img
						src="/images/dashboard.png"
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
