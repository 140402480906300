import axios from "axios";
import React, { useEffect, useState } from "react";
import { adminAPI } from "../../utils/apiStrings";

function UserStateLaws({ userDetails }) {
  const [client_states, setClient_states] = useState([]);

  const getUserStateLaws = async () => {
    await axios
      .get(`${adminAPI()}/client-states?client_id=${userDetails.client_id}`)
      .then((response) => {
        setClient_states(response.data.data);
      });
  };

  useEffect(() => {
    getUserStateLaws();
  }, [userDetails]);

  return (
    <div className="userStateLaws">
      <h1 className="userStateLaws__title">{`${userDetails.full_name}'s list of State Laws`}</h1>
      <table>
        <thead className="userStateLaws__thead">
          <tr>
            <th>S/N</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {client_states.map((state, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{state}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UserStateLaws;
