import React, { useEffect, useRef, useState } from "react";
import OverviewCard from "../../components/OverviewCard";
import axios from "axios";
import { adminAPI } from "../../utils/apiStrings";
import TransactionTable from "../../components/Admin/TransactionTable";
import Paginator from "../../components/Paginator";
import { IoCloseSharp } from "react-icons/io5";
import { CiCalendarDate } from "react-icons/ci";
import Button from "../../components/FormComponents/Button";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import { checkAdmin } from "../../utils/session";
import { useNavigate } from "react-router-dom";
import debounce from "../../utils/debounce";

function AdminTransaction() {
  const navigate = useNavigate();

  const [transactions, setTransactions] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalLawsSold, setTotalLawsSold] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const [filter, setFilter] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filterResult, setFilterResult] = useState([]);
  const [response, setResponse] = useState({ status: "", message: "" });
  const [id, setId] = useState("");

  const [pageLimit, setPageLimit] = useState(5);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  let currentUsers = [];
  const [pageGroup, setPageGroup] = useState(() =>
    new Array(pageLimit).fill().map((_, index) => index + 1)
  );

  const [filterLloading, setFilterLloading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  let fromDateNew = fromDate ? new Date(fromDate) : "";
  let toDateNew = toDate ? new Date(toDate) : "";
  fromDateNew =
    fromDateNew &&
    `${fromDateNew.getFullYear()}-${
      fromDateNew.getMonth() + 1 < 10 ? "0" : ""
    }${fromDateNew.getMonth() + 1}-${fromDateNew.getDate()}`;

  toDateNew =
    toDateNew &&
    `${toDateNew.getFullYear()}-${toDateNew.getMonth() + 1 < 10 ? "0" : ""}${
      toDateNew.getMonth() + 1
    }-${toDateNew.getDate()}`;
  const [showCalendar, setShowCalendar] = useState(false);

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
  const [showOverlay, setShowOverlay] = useState(false);

  // Ref for date boxes
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);

  // Toggle the calendar and overlay
  const toggleCalendar = (dateType, e) => {
    e.stopPropagation();

    // Determine which date box triggered the event
    const ref = dateType === "from" ? fromDateRef.current : toDateRef.current;

    // Get the position of the clicked date box
    const rect = ref.getBoundingClientRect();
    setCalendarPosition({
      top: rect.bottom + window.scrollY, // Position below the date box
      left: rect.left, // Align to the left of the date box
    });
    if (dateType === "from") {
      setShowCalendar("from");
    } else if (dateType === "to") {
      setShowCalendar("to");
    }
    setShowOverlay(true);
  };

  const handleDateSelection = (dateType, e) => {
    console.log(new Date(e).toDateString().substring(4, 16));
    if (dateType === "from") {
      setFromDate(new Date(e).toDateString().substring(4, 16));
    } else {
      setToDate(new Date(e).toDateString().substring(4, 16));
    }
    setShowOverlay(false);
  };

  const closeCalendar = () => {
    setShowOverlay(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleApplyFilter = async () => {
    if (fromDate === null || toDate === null) {
      return;
    }
    await getTransactions(1, fromDateNew, toDateNew);
  };

  const changePage = (page) => {
    setCurrentPage(page);
    getTransactions(page, fromDateNew, toDateNew);
    setPageGroup(getPageGroup(page));
  };

  const getPageGroup = (page) => {
    let start = Math.floor((page - 1) / pageLimit) * pageLimit;
    const newGroup = new Array(pageLimit)
      .fill()
      .map((_, index) => start + index + 1);
    return newGroup;
  };

  const getTransactions = async (
    page,
    from_date = "",
    to_date = "",
    search = ""
  ) => {
    setFilterLloading(true);
    try {
      const response = await axios.get(
        `${adminAPI()}/transaction-report?page=${page}${
          from_date ? `&from_date=${from_date}` : ""
        }${to_date ? `&to_date=${to_date}` : ""}${
          search ? `&search=${search}` : ""
        }`
      );
      const {
        data: {
          data: { data },
        },
      } = response;
      // console.log(response.data.data);
      setTotalTransactions(response.data.data.total);
      setCurrentPage(response.data.data.current_page);
      setTransactions(data);
    } catch (error) {}
    setFilterLloading(false);
    setDropdownVisible(false);
  };

  useEffect(() => {
    const { session, token } = checkAdmin();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (!session) {
      navigate("login");
    }
  });

  const getTotalSales = async () => {
    await axios
      .get(`${adminAPI()}/total-sales`)
      .then((response) => {
        setTotalAmount(
          response.data.data[0].total_amount.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })
        );
        setTotalLawsSold(response.data.data[0].laws_sold);
      })
      .catch((error) => {
        console.error(`Error fetching total sales: `, error);
      });
  };

  const searchUser = (string) => {
    debounce(() => getTransactions(1, "", "", string.toLowerCase()), 1000);
  };

  const closeOptions = () => {
    setId(null);
  };

  useEffect(() => {
    getTransactions(1);
    getTotalSales();
  }, []);

  currentUsers = searchResult.length ? searchResult : transactions;

  return (
    <div className="transaction">
      <div
        className={`transaction__response ${
          response.status
            ? response.status?.toString().includes("20")
              ? "transaction__response--success"
              : "transaction__response--error"
            : ""
        }`}
        onClick={() => setResponse({ status: "" })}
      >
        {response.message || "Action Successful"} &#x24E7;
      </div>
      <div className="transaction__cards">
        <OverviewCard
          image="/images/total-users.svg"
          color="purple"
          label="Total Number of Laws Sold"
          text={totalLawsSold}
        />
        <OverviewCard
          image="/images/total-users.svg"
          color="purple"
          label="Total Amount"
          text={totalAmount}
        />
      </div>

      <div className="transaction__transactions">
        <div className="transaction__actions">
          <div className="transaction__view">
            <div className="transaction__search">
              <span className="transaction__icon transaction__icon--search"></span>
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                className="transaction__input"
                onChange={(e) => searchUser(e.target.value)}
              />
            </div>

            <div className="transaction__filter" onClick={toggleDropdown}>
              <img src="/images/filter2.svg" alt="filter" />
              <span className="transaction__select">Filter</span>
              {isDropdownVisible && (
                <div
                  className="transaction__filter--modal filterDropdown"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="title">
                    <p>Filter</p>{" "}
                    <IoCloseSharp
                      onClick={toggleDropdown}
                      cursor="pointer"
                      size="20px"
                    />
                  </div>

                  <div className="filterDate">
                    <p>Start Date</p>

                    <div
                      className="dateBox"
                      ref={fromDateRef}
                      onClick={(e) => toggleCalendar("from", e)}
                    >
                      {fromDate ? fromDate : "Select a date"}{" "}
                      <CiCalendarDate size="25px" />
                    </div>
                  </div>
                  <div className="filterDate">
                    <p>End Date</p>

                    <div
                      className="dateBox"
                      ref={toDateRef}
                      onClick={(e) => toggleCalendar("to", e)}
                    >
                      {toDate ? toDate : "Select a date"}{" "}
                      <CiCalendarDate size="25px" />
                    </div>
                  </div>

                  <Button
                    text="Apply Filter"
                    loading={filterLloading}
                    onClick={(e) => handleApplyFilter()}
                  />

                  {/* Calendar overlay */}
                  {showOverlay && (
                    <div className="calendar-overlay" onClick={closeCalendar}>
                      <div
                        className="calendar-container"
                        style={{
                          position: "absolute",
                          top: calendarPosition.top,
                          left: calendarPosition.left,
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="calendar-title">
                          <p>Calendar</p>
                          <IoCloseSharp
                            onClick={closeCalendar}
                            cursor="pointer"
                            size="20px"
                          />
                        </div>
                        {showCalendar === "from" && (
                          <DayPicker
                            mode="single"
                            disabled={{ after: new Date() }}
                            selected={fromDate}
                            onDayClick={(e) => handleDateSelection("from", e)}
                            captionLayout="label"
                            footer={
                              fromDate ? `Selected: ${fromDate}` : "Pick a day."
                            }
                            defaultMonth={fromDate}
                            endMonth={new Date()}
                            numberOfMonths={12}
                            pagedNavigation
                          />
                        )}
                        {showCalendar === "to" && (
                          <DayPicker
                            mode="single"
                            disabled={{
                              before: new Date(fromDate),
                              after: new Date(),
                            }}
                            selected={new Date(toDate)}
                            onDayClick={(e) => handleDateSelection("to", e)}
                            captionLayout="label"
                            footer={
                              toDate ? `Selected: ${toDate}` : "Pick a day"
                            }
                            defaultMonth={toDate}
                            endMonth={new Date()}
                            numberOfMonths={12}
                            pagedNavigation
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="transaction__table">
          {filterLloading ? (
            <Loader />
          ) : transactions.length === 0 ? (
            <NoData />
          ) : (
            <TransactionTable
              data={
                filter !== ""
                  ? filterResult
                  : searchResult.length
                  ? searchResult
                  : transactions
              }
              setResponse={setResponse}
              id={id}
              setId={setId}
              closeOptions={closeOptions}
            />
          )}
          {currentUsers && (
            <div className="transaction__paginator">
              <Paginator
                perPage={perPage}
                totalLaws={searchResult?.length || totalTransactions}
                changePage={changePage}
                currentPage={currentPage}
                pageGroup={pageGroup}
                setPageGroup={setPageGroup}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminTransaction;
