import React, { useEffect } from "react";
import OverviewOptions from "./OverviewOptions";

const ClientTable = ({
  data,
  setResponse,
  closeOptions,
  id,
  setId,
  activateBackDrop,
}) => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email Address</th>
          <th className="states">Number of State Laws</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((user) => (
          <tr key={user.client_id}>
            <td>{`${user.full_name}`}</td>
            <td>{user.email}</td>
            <td className="states">{user.states_count}</td>
            <td>
              <span
                className="view"
                onClick={() =>
                  activateBackDrop({
                    client_id: user.client_id,
                    full_name: user.full_name,
                  })
                }
              >
                View
              </span>
              <OverviewOptions
                userId={user.client_id}
                id={id}
                setId={setId}
                closeOptions={closeOptions}
                setResponse={setResponse}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClientTable;
